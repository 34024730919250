import {BankAccountDTO_OLD} from "@/dto/BankDetailsDTO_OLD";
import AddressDTO from "@/dto/AddressDTO";
import BankAccountDTO from "@/dto/BankAccountDTO";
import {FederalTaxClassification} from "@/constants/FederalTaxClassification";

export default class SaveW9RequestDTO {

    public publicId: string | null = null;
    public name: string | null = null;
    public businessName : string | null = null;
    public classification: FederalTaxClassification | null = null;
    public llcTaxClassification: LLCTaxClassification | null = null;
    public classificationOtherDescription: string | null = null;
    public haveAnyPartners: boolean | null = null;
    public payeeExemptionCode: number | null = null;
    public fatcaExemptionCode: number | null = null;
    public address = new AddressDTO();
    public bankAccount = new BankAccountDTO();
    public ssn: string | null = null;
    public ein: string | null = null;

}
export enum LLCTaxClassification {
    C = 'C',
    S = 'S',
    P = 'P'
}